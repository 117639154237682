<script>
import GetCampaignErrorNotification from '@/components/Notifications/Campaign/GetCampaignErrorNotification';
import GetFinancialErrorNotification from '@/components/Notifications/Campaign/GetFinancialErrorNotification';
import FinancialTable from '@/components/Campaign/FinancialTable';

import financingService from '@/services/Financing/financingService';
import forumService from '@/services/Forum/forumService';
import ordersService from '@/services/Orders/ordersService';

import settingsService from '@/services/Settings/settingsService';
import { CWLInvestimentoDashboard } from '@/store/cwl_enums';
import attachmentsService from '@/services/Attachments/attachmentsService';
import { PessoaCaracteristica } from '@/store/cwl_enums';

import firebase from 'firebase';

import moment from 'moment';

const tableColumns = ['Valor do Investimento', 'Forma de Pagamento', 'Data do Aporte', 'Status de Pagamento', 'Remuneração Acumulada', 'Contratos'];

export default {
  name: 'campaign-details',
  components: {
    FinancialTable,
  },
  data() {
    return {
      campaign: {},
      financing: {},
      forum: {},
      financials: [],
      isLoading: true,
      table: {
        columns: [...tableColumns],
      },
      modalidade: process.env.VUE_APP_TYPE,
      modalidadeOferta: '',
      prazo: '',
      nomeOferta: '',

      infoTitle: '',
      infoDate: '',
      infoTime: '',
      infoDescription: '',
      infoFile: '',
      relevantInfos: [],
      
      idQuestion: '',
      question: '',
      answer: '',
      created_at: '',
      forumQuestions: [],
    };
  },
  computed: {
    campaignId() {
      return this.$route.params.id;
    },
    generalReport() {
      return this.$route.params.report;
    },
    envPath() {
      return process.env.VUE_APP_ROOT_SITE;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    },
    role() {
      return this.$store.getters.userClaim.role.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    forumRef() {
      return firebase.firestore().collection('forum');
  },
  },
  mounted() {
    this.getCampaignDetails();
  },
  methods: {
    async getCampaignDetails() {
      try {
        const campaign = await financingService.get(this.campaignId);
        this.campaign = campaign.data();
        this.nomeOferta = this.campaign.name;

        this.relevantInfos = this.campaign.relevantInfos;
        const questionsForum = await forumService.getAllOrderByCreate(this.campaignId);
      
        let docForum = [];
        
        questionsForum.forEach((item) => {
          docForum.push({
            id: item.data().id,
            question: item.data().question,
            answer: item.data().answer, 
            answered: item.data().answered,
          })
        });

        this.forumQuestions = docForum || []

        if ( this.campaign.fields['modalidade_remuneracao'] ) {
          this.modalidadeOferta = this.campaign.fields['modalidade_remuneracao'].value;
        }
        
        if ( !this.generalReport && document.getElementById('parsedContent')) {
          document.getElementById(
            'parsedContent',
          ).innerHTML = await campaign.data().content;

          this.table.columns = ['Valor do Investimento', 'Forma de Pagamento', 'Data do Aporte', 'Status de Pagamento', 'Remuneração Acumulada', 'Contratos'];
        }
        else {
          this.table.columns = ['Investidor', 'Código da Operação', 'Número da CCB', 'Valor do Investimento', 'Remuneração Acumulada', 'Valor da Remuneração', 'Carência Total do Lote'];
        }

        this.getFinancialDetails();
      } catch (e) {
        this.$notify({
          component: GetCampaignErrorNotification,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async getFinancialDetails() {
      try {
        function compare(a, b) {
          let dateA = moment(b.order_date);
          let dateB = moment(a.order_date);
          if (dateA < dateB) {
            return -1;
          }
          else if (dateA > dateB) {
            return 1;
          }
          return 0;
        }

        let preOrdersToSort;
        if ( !this.generalReport ) {
          preOrdersToSort = await ordersService.getOrderByUserAndId(this.loggedPessoa.pessoa_id, this.campaignId);
        }
        else {
          preOrdersToSort = await ordersService.getOrdersByFinancingId(this.campaignId);
          if ( this.campaign.fields['prazo_oferta'] && this.campaign.fields['prazo_oferta'].value ) {
            this.prazo = this.campaign.fields['prazo_oferta'].value;
          }
        }

        // this.relevantInfos = this.financing.relevantInfos || []
        
        const privateSettings = await settingsService.getPrivateSettings();
        const investimentoDashboard = privateSettings.investimento_dashboard ? privateSettings.investimento_dashboard : CWLInvestimentoDashboard.PagosPendentes;
        var ordersToSort = preOrdersToSort.filter((order) => {
          if ( investimentoDashboard == CWLInvestimentoDashboard.PagosPendentes ) {
            return true;
          }
          else if ( investimentoDashboard == CWLInvestimentoDashboard.Pagos ) {
            return order.status == "Pago";
          }
          else {
            return false;
          }
        });

        var ordersMapped = await Promise.all(ordersToSort.map(async (order) => {
          var termos = [];
          if ( order.aceite_termos_hash ) {
            let documento = order.pessoa.documento.numero.replace(".", "").replace(".", "").replace("-", "");
            let path = "TermosAporte/" + order.item.id + "/" + documento + "/" + order.aceite_termos_hash;
            const attachments = await attachmentsService.getTermosByPath(path);
            termos = attachments.items;
          }
          order.termos = termos;
          return order;
        }));

        this.financials = ordersMapped.sort(compare);
        this.isLoading = false;
      } catch (e) {
        // this.$notify({
        //   component: GetFinancialErrorNotification,
        //   icon: 'tim-icons icon-bell-55',
        //   horizontalAlign: 'center',
        //   verticalAlign: 'top',
        //   type: 'warning',
        //   timeout: 3000,
        // });
      }
    },
    redirectToSite() {
      location.replace(`${this.envPath}project-detail/${this.campaignId}`);
    },
    async salvarPergunta() {
      try {
        const forum =  {
          question: this.question,
          answer: '',
          answered: false,
          financing_id: this.campaignId,
          created_at: new Date()
        };

       await forumService.create(forum); 
       this.forumQuestions.unshift(forum);

        this.question = '';
        
        this.$notify({
          title:
            'Pergunta adicionada com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
        this.$forceUpdate();
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro perguntar. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },

    async downloadPDF(file) {
      try {
        const financing = await financingService.findById(this.campaignId);
        const path = 'Financings/' + financing.id;

        await financingService.downloadFinancing(file, path);
      }
      catch (e) {
        console.log('Erro ao fazer download do pdf', e);
      }
    },

    formattedDate(timestamp) {
      const value = new Date(timestamp.seconds*1000);
      return moment(value).format('LLL');
    },

    redirectToSite() {
      location.replace(`${this.envPath}project-detail/${this.campaignId}`);
    },
    async deletarQuestao(resp) {
      try {
        forumService.delete(resp.id);

        this.forumQuestions = this.forumQuestions.filter(item => item !== resp);

        this.$notify({
          title:
            'Pergunta deletada com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro ao responder. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async salvarResposta(resp) {
      try {

       await forumService.update(resp.id, resp);
        this.question = '';

        this.$notify({
          title:
            'Resposta adicionada com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro ao responder. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
    async deletarResposta(resp) {
      try {
        resp.answer = '';
        await forumService.update(resp.id, resp);
        
        this.$notify({
          title:
            'Resposta deletada com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro ao responder. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<template>
  <div class="row campaign-details">
    <div class="col-md-12">
      <card v-if="!generalReport">
        <div class="card-header"  v-if="!isGestor">
          <h3 class="card-title" v-if="modalidade == 'doacao'">
            <router-link to="/dashboard" class="fas fa-arrow-left" tag="i" />
            Detalhes da Campanha
          </h3>
          <h3 class="card-title" v-if="modalidade == 'equity'">
            <router-link to="/dashboard" class="fas fa-arrow-left" tag="i" />
            Detalhes da Oferta
          </h3>

          <!-- <button class="btn" @click="redirectToSite()">
            Visualizar no Site
          </button> -->
        </div>

        <div class="campaign-informations" v-if="!isGestor">
          <div id="parsedContent"></div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <base-input
                  label="Modalidade"
                  placeholder="Modalidade"
                  v-model="modalidadeOferta"
                  :disabled="true"
                ></base-input>
              </div>
            </div>
          </div>
        </div>
      </card>

      <card v-if="modalidade == 'equity' && !generalReport && !isGestor">
        <div class="card-header">
          <h3 class="card-title">Informações Periódicas</h3>
        </div>
        <div class="card-body">
          <card v-for="(item, index) in relevantInfos" class="col-md-12" style="color: #494949; letter-spacing: 1px; margin-bottom: 20px;">
            <div class="row" :key="item.infoTitle+index">
              <div class="col-md-3">
                <strong style="font-weight: 600;">TÍTULO </strong>
                <p>{{ item.infoTitle }}</p>
              </div>
              <div class="col-md-3">
                <strong style="font-weight: 600;">DATA </strong>
                <p>{{ formattedDate(item.infoCreated_at) }}</p>
              </div>
              <div class="col-md-5">
                <strong style="font-weight: 600;">DESCRIÇÃO </strong>
                <p>{{ item.infoDescription }}</p>
              </div>
              <div class="col-md-1" style="display: flex; justify-content: flex-end; align-items: center;" v-if="item.infoFile">
                <!-- <p>{{ item.infoFile }}</p> --> 
                <svg 
                  width="30"
                  height="65" 
                  viewBox="0 0 30 65" fill="none" xmlns="http://www.w3.org/2000/svg" 
                  style="cursor: pointer;"
                  @click="downloadPDF(item.infoFile)"
                >
                  <path d="M15 31.9844V42.6094M15 42.6094L18.75 38.8594M15 42.6094L11.25 38.8594M10 26.4044C10.9354 26.5382 11.8019 26.9725 12.4688 27.6419M21.875 35.1094C23.7738 35.1094 25 33.5706 25 31.6719C25 30.9202 24.7535 30.1892 24.2983 29.5909C23.8432 28.9926 23.2045 28.56 22.48 28.3594C22.3686 26.9575 21.7875 25.6341 20.8309 24.6033C19.8742 23.5724 18.5979 22.8943 17.2081 22.6787C15.8184 22.463 14.3966 22.7223 13.1725 23.4148C11.9484 24.1072 10.9936 25.1922 10.4625 26.4944C9.3443 26.1844 8.14875 26.3314 7.13886 26.9029C6.12898 27.4744 5.38749 28.4237 5.07752 29.5419C4.76755 30.6601 4.91448 31.8557 5.486 32.8656C6.05752 33.8754 7.0068 34.6169 8.12502 34.9269" stroke="#D8D6DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> 
              </div>
            </div>
          </card>
        </div>
      </card>

      <card v-if="!isGestor">
        <div class="card-header" v-if="!generalReport">
          <h3 class="card-title" v-if="modalidade == 'doacao'">Investimentos nesta Campanha</h3>
          <h3 class="card-title" v-if="modalidade == 'equity'">Investimentos nesta Oferta</h3>
        </div>

        <div class="card-header" v-if="generalReport">
          <h3 class="card-title">Investimentos na Oferta {{ nomeOferta }}</h3>
        </div>

        <div class="financial-details" v-if="!isLoading">
          <financial-table
            :data="financials"
            :campaign="campaign"
            :columns="table.columns"
            :prazo="prazo"
            thead-classes="text-primary" 
          />
        </div>
      </card>

      <card class="pl-3">
        <div class="card-header">
          
          <div class="row">
            <h5 slot="header" class="title col-md-12" v-if="isGestor" style="padding-left: 0;">
              <router-link
                :to="`/edit-financing/${this.campaignId}`"
                class="fa fa-arrow-left mr-2"
                title="Voltar"
              ></router-link>
              Voltar para oferta
            </h5>

              <h3 class="card-title col-md-12" style="padding-left: 0;">Fórum</h3>
          </div>
        </div>

        <div class="row" v-if="!isGestor"> 
          <div class="col-md-10">
            <base-input 
              placeholder="Digite sua pergunta"
              v-model="question"
            />
            
          </div>
          <div>
            <button
              type="button"
              @click="salvarPergunta()"
              class="btn btn-sm"
            >
              Perguntar
            </button>
          </div>
        </div>

        <div class="row">
          <h5 class="pl-3 pt-4"><strong>Ultimas perguntas</strong></h5>
        </div>

        <div class="card-answer" v-for="(item) in forumQuestions">
          <div class="row pt-4" :key="item.id">
            <div class="row">
              <h5 class="pl-3 ml-3">{{ item.question }}</h5>

              <svg  v-if="isGestor"
                style="cursor: pointer; fill: #dc3848;"
                class="ml-3"
                @click="deletarQuestao(item)"
                xmlns="http://www.w3.org/2000/svg" 
                height="1em" viewBox="0 0 448 512"  
               >
                <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
              </svg>
            </div>
          </div>

          <div class="row" style="position: relative;">
            <div style="width: 12px; position: absolute; left: 25px; top: -4px;">
              <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="turn-down-right">
                <path d="M19.4,15.7L14.8,11c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2.9,2.9h-7c-1.7,0-3-1.3-3-3V3c0-0.6-0.4-1-1-1s-1,0.4-1,1
                  v9.4c0,2.8,2.2,5,5,5h7l-2.9,2.9c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.6,0.4,1,1,1c0.3,0,0.5-0.1,0.7-0.3l4.6-4.6c0,0,0,0,0,0
                  C19.8,16.7,19.8,16.1,19.4,15.7z">
                </path>
              </svg>
            </div>
            <div v-if="!isGestor">
              <p class="pl-5">{{ item.answer }}</p>
            </div>
            
            <div v-if="isGestor" class="row pl-3 ml-4">
              <base-input
                placeholder="Digite aqui sua resposta"
                v-model="item.answer"
              />
              <div>
              <button
                v-if="isGestor"
                type="button"
                @click="salvarResposta(item)"
                class="btn btn-sm ml-3"
              >
                Responder
              </button>
            </div>
              <svg
                style="cursor: pointer; fill: #dc3848;"
                class="ml-3 mt-2"
                @click="deletarResposta(item)"
                xmlns="http://www.w3.org/2000/svg" 
                height="1em" viewBox="0 0 448 512"  
               >
                <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
              </svg>
            </div>
          </div>
        </div>

      </card>
    </div>
  </div>
</template>