<script>
import Vue from 'vue';

import getTransactions from '@/functions/getTransactions';
import ordersService from '@/services/Orders/ordersService';

import { DoubleBounce } from 'vue-loading-spinner';

import moment from 'moment';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import XLSX from 'xlsx';

import { vueTopprogress } from 'vue-top-progress';

export default {
  data() {
    return {
      transacoes: [],
      transacoes_excel: [],
      financing: {},
      dataEncerramentoOperacao: '',
      isLoading: true,
      filtroSomenteAutorizado: false,
      filtroSomentePago: false,
      filtroSomentePendente: false
    };
  },
  components: {
    vueTopprogress,
    DoubleBounce
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
    routeType() {
      return this.$route.params.type;
    },
  },
  mounted() {
    this.financing = this.$CWLNewFinanciamentoPadrao();
    this.loadTransactions();
  },
  methods: {
    async loadTransactions() {
      try {
        this.transacoes = [];
        let that = this;
        const financingId = this.routeId;
        const accountKind = this.getAccountKind();
        let transacoes_to_map = await getTransactions(financingId, accountKind);

        console.log('transacoes to map: ', transacoes_to_map)
        this.transacoes = await Promise.all(transacoes_to_map.map(async (transacao) => {
          let orders = await ordersService.getOrdersByFieldEqualsTo('transacao_id', transacao.IdTransaction);
          var order = {
            pessoa: {
              documento: {
                numero: '--'
              }
            },
            ccb: {
              numero_ccb: '--',
              data_ccb: '--',
              data_vencimento: '--',
              valor_bruto: 0,
              valor_cad: 0,
              valor_iof: 0,
              valor_liquido: 0,
              valor_juro_am: 0,
              taxa_safe2pay: 0
            }
          };
          if ( orders.length > 0 ) {
            order = orders[0];
            order.ccb.data_ccb = moment(order.ccb.simulacao.payload.DataDeLiberacao, "YYYY/MM/DD").format('DD/MM/YYYY');
            order.ccb.data_vencimento = moment(order.ccb.simulacao.payload.DataDeVencimento, "YYYY/MM/DD").format('DD/MM/YYYY');
            order.ccb.valor_bruto = order.ccb.simulacao.payload.ValorBruto || 0;
            order.ccb.valor_cad = order.ccb.simulacao.payload.ValorDaCAD;
            order.ccb.valor_iof = order.ccb.simulacao.response.IOF_NF;
            order.ccb.valor_liquido = order.ccb.simulacao.payload.ValorLiquido.toFixed(2);
            order.ccb.valor_juros_am = order.ccb.simulacao.response.CET_NF_MENSAL || 0;
            order.ccb.taxa_safe2pay = order.ccb.taxa_safe2pay || 0;

            if ( that.financing.name.length == 0 ) {
              that.financing.name = order.item.description;
            }

            if ( that.dataEncerramentoOperacao.length == 0 ) {
              that.dataEncerramentoOperacao = moment(order.ccb.simulacao.payload.DataDeLiberacao, "YYYY/MM/DD").format('DD-MM-YYYY');
            }
          }

          transacao.order = order;

          that.transacoes_excel.push({
            ID_SAFE2PAY: transacao.IdTransaction,
            // VALOR_APORTE: transacao.Amount,
           //  TAXA_SAFE2PAY: order.ccb.taxa_safe2pay,
            STATUS_SAFE2PAY: transacao.Message,
            NOME_INVESTIDOR: transacao.Customer.Name,
            CPF_INVESTIDOR: order.pessoa.documento.numero,
            CCB: order.ccb.numero_ccb,
            DATA_CCB: order.ccb.data_ccb,
            DATA_VENCIMENTO: order.ccb.data_vencimento,
            VALOR_BRUTO: parseFloat(order.ccb.valor_bruto),
            VALOR_CAD: parseFloat(order.ccb.valor_cad),
            VALOR_IOF: parseFloat(order.ccb.valor_iof),
            VALOR_LIQUIDO: parseFloat(order.ccb.valor_liquido)
            // VALOR_JUROS_AM: order.ccb.valor_juros_am
          });

          return transacao;
        }));
        this.transacoes_swap = this.transacoes;
        this.isLoading = false;
      } catch (error) {
        console.error('load transactions error', error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

      }
    },
    getAccountKind() {
      let accountKind;
      if (this.routeType == 'campanha') {
        accountKind = 'beneficiary';
      } else if (this.routeType == 'cwl') {
        accountKind = 'platform';
      } else if (this.routeType == 'social-agent') {
        accountKind = 'agent';
      } else if (this.routeType == 'marketplace') {
        accountKind = 'marketplace';
      } else {
        throw new Error('Tipo de transação não suportado!');
      }
      return accountKind;
    },
    splitName(transaction) {
      const splits = transaction.Splits || [];
      return splits.length > 0 ? splits[splits.length - 1].Name : '';
    },
    splitAmount(transaction) {
      const splits = transaction.Splits || [];
      return splits.length > 0 ? splits[splits.length - 1].Amount : '';
    },
    downloadExcel() {
      let nomeArquivo = "MCF_" + this.financing.name.replace(" ", "_").replace(" ", "_").replace(" ", "_").replace(" ", "_").replace(" ", "_") + "_" + this.dataEncerramentoOperacao + ".xlsx";
      const data = XLSX.utils.json_to_sheet(this.transacoes_excel);

      const columnsToFormat = ['H', 'I', 'J', 'K'];

      columnsToFormat.forEach((customCol) => {
        for (let i = 2; i <= this.transacoes_excel.length + 1; i++) {
          data[customCol + i].z = 'R$ #,##0.00';
        }
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'Planilha1');
      XLSX.writeFile(wb, nomeArquivo);
    },
    checkHabilitarSomenteAutorizado() {
      this.filtroSomenteAutorizado = !this.filtroSomenteAutorizado;
      this.reloadTransacoes();
    },
    checkHabilitarSomentePendente() {
      this.filtroSomentePendente = !this.filtroSomentePendente;
      this.reloadTransacoes();
    },
    checkHabilitarSomentePago() {
      this.filtroSomentePago = !this.filtroSomentePago;
      this.reloadTransacoes();
    },
    reloadTransacoes() {
      this.transacoes = [];
      this.transacoes_swap.map((transacao) => {

        if (this.filtroSomenteAutorizado || this.filtroSomentePago || this.filtroSomentePendente) {
          if (this.filtroSomenteAutorizado && transacao.Message === 'Autorizado') {
            this.transacoes.push(transacao)
          }

          if (this.filtroSomentePago && transacao.Message === 'Pago') {
            this.transacoes.push(transacao)
          }

          if (this.filtroSomentePendente && transacao.Message === 'Pendente') {
            this.transacoes.push(transacao)
          }
        } else {
          this.transacoes.push(transacao)
        }

      });
    }
  },
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
        <card>
          <div v-if="isLoading" class="loading-spinner">
            <double-bounce></double-bounce>
          </div>
          <div class="row" v-if="!isLoading">
            <div class="col-3">
              <input
                  name="filtro_somente_autorizado"
                  ref="filtro_somente_autorizado"
                  type="checkbox"
                  v-model="filtroSomenteAutorizado"
              />
              <label
                  for="filtro_somente_autorizado"
                  style="margin-left: 5px;"
                  @click="checkHabilitarSomenteAutorizado"
              >
                  FILTRAR SOMENTE AUTORIZADO
              </label>
            </div>
            <div class="col-3">
              <input
                  name="filtro_somente_pago"
                  ref="filtro_somente_pago"
                  type="checkbox"
                  v-model="filtroSomentePago"
              />
              <label
                  for="filtro_somente_pago"
                  style="margin-left: 5px;"
                  @click="checkHabilitarSomentePago"
              >
                  FILTRAR SOMENTE PAGO
              </label>
            </div>
            <div class="col-3">
              <input
                  name="filtro_somente_pendente"
                  ref="filtro_somente_pendente"
                  type="checkbox"
                  v-model="filtroSomentePendente"
              />
              <label
                  for="filtro_somente_pendente"
                  style="margin-left: 5px;"
                  @click="checkHabilitarSomentePendente"
              >
                  FILTRAR SOMENTE PENDENTE
              </label>
            </div>
            <br><br>
            <div class="col-12">
              <div style="overflow: overlay !important;">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr style="text-align: center;">
                      <th>ID SAFE2PAY</th>
                      <th>VALOR APORTE</th>
                      <th>TAXA SAFE2PAY</th>
                      <th>STATUS SAFE2PAY</th>
                      <th>NOME INVESTIDOR</th>
                      <th>CPF INVESTIDOR</th>
                      <th>CCB</th>
                      <th>DATA CCB</th>
                      <th>DATA VENCIMENTO</th>
                      <th>VALOR BRUTO</th>
                      <th>VALOR CAD</th>
                      <th>VALOR IOF</th>
                      <th>VALOR LIQUIDO</th>
                      <!-- <th>VALOR JUROS A.M.</th> -->
                      <th>PaymentDate</th>
                      <th>Reference</th>
                      <th>TaxValue</th>
                      <th>Split Name</th>
                      <th>Split Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in transacoes" :key="index">
                      <td>{{ item.IdTransaction }}</td>
                      <td>{{ item.Amount | currency }}</td>
                      <td>{{ item.TaxValue | currency }}</td>
                      <td>{{ item.Message }}</td>
                      <td>{{ item.Customer.Name }}</td>
                      <td>{{ item.order.pessoa.documento.numero }}</td>
                      <td>{{ item.order.ccb.numero_ccb }}</td>
                      <td>{{ item.order.ccb.data_ccb }}</td>
                      <td>{{ item.order.ccb.data_vencimento }}</td>
                      <td>{{ item.order.ccb.valor_bruto | currency }}</td>
                      <td>{{ item.order.ccb.valor_cad | currency }}</td>
                      <td>{{ item.order.ccb.valor_iof | currency }}</td>
                      <td>{{ item.order.ccb.valor_liquido | currency }}</td>
                      <!-- <td>{{ item.order.ccb.valor_juros_am | currency }}</td> -->
                      <td>{{ item.Reference }}</td>
                      <td>{{ item.PaymentDate }}</td>
                      <td>{{ item.TaxValue }}</td>
                      <td>{{ splitName(item) }}</td>
                      <td>{{ splitAmount(item) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row" v-if="!isLoading">
            <button
              type="button"
              class="btn btn-sm"
              style="padding: 10px"
              @click="downloadExcel"
            >
              Download Excel
            </button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
